import './dataInsights.css';
import filetypeWidgetRec from '../../assets/filetypeWidgetRec.png';
import filetypeWidgetSQ from '../../assets/filetypeWidgetSQ.png';
import storagetrendingWidgetSQ from '../../assets/storagetrendingWidgetSQ.png';
import storageusageWidgetTall from '../../assets/storageusageWidgetTall.png';
import totalsizeWidgetSQ from '../../assets/totalsizeWidgetSQ.png';
import { Grid } from 'semantic-ui-react';
import React from 'react';

function DataInsights() {
    return (
        <div className="dataInsightsComponent"> 
            
            <div className="dataInsightsContainer">
                <Grid container columns={4}>
                    <div className='dataInsight'>
                    <div className="dataInsightsHeading">Actionable Data Insights</div>
                        <div className='col1 moveLeftRight'>
                            <div><img src={storagetrendingWidgetSQ} className="widget" alt="widget" /></div>
                        </div>
                        <div className='col2 moveLeftRight'>
                            <div><img src={storageusageWidgetTall} className="widget" alt="widget" /></div>
                        </div>
                        <div className='col3 moveLeftRight'>
                            <div className='col3row1 moveLeftRight'>
                                <div className='moveLeftRightLevel2'><img src={filetypeWidgetSQ} className="widget" alt="widget" /></div>
                                <div className='moveLeftRightLevel3'><img src={totalsizeWidgetSQ} className="widget" alt="widget" /></div> 
                            </div>
                            <div className='moveLeftRightLevel1'><img src={filetypeWidgetRec} className="widget" alt="widget" /></div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default DataInsights;